// @flow strict
import React from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import { Link } from 'gatsby';
import type { Edges } from '../../types';
import styles from './Feed.module.scss';

type Props = {
  edges: Edges
};

const Feed = ({ edges }: Props) => {
  return (
    <div className={styles['feed']}>
      {edges.map(edge => {
        const { tags } = edge.node.frontmatter;

        return (
          <div className={styles['feed__item']} key={edge.node.fields.slug}>
            <div className={styles['feed__item-meta']}>
              <time
                className={styles['feed__item-meta-time']}
                dateTime={moment(edge.node.frontmatter.date).format(
                  'MMMM D, YYYY'
                )}
              >
                {moment(edge.node.frontmatter.date).format('MMMM YYYY')}
              </time>
              <span className={styles['feed__item-meta-divider']} />
              <span className={styles['feed__item-meta-category']}>
                <Link
                  to={`/tag/${_.kebabCase(edge.node.frontmatter.category)}`}
                  className={styles['feed__item-meta-category-link']}
                >
                  {edge.node.frontmatter.category}
                </Link>

                {tags && tags.length > 1 ? (
                  <span className={styles['feed__other-tags']}>
                    {' '}
                    and {tags.length - 1} other tag{tags.length > 2 ? 's' : ''}.
                  </span>
                ) : (
                  <span></span>
                )}
              </span>
            </div>
            <h2 className={styles['feed__item-title']}>
              <Link
                className={styles['feed__item-title-link']}
                to={edge.node.fields.slug}
              >
                {edge.node.frontmatter.title}
              </Link>
            </h2>
            <p className={styles['feed__item-description']}>
              {edge.node.frontmatter.description}
            </p>
            <Link
              className={styles['feed__item-readmore']}
              to={edge.node.fields.slug}
            >
              Read
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Feed;
